const advent_days = [
    {
        id: 1,
        prize_image: 'HR-11.png',
        grid_image: 'KALENDAR-adventski_1-24.jpeg',
        tail_image: 'rep-zvijezde-1-dan-min.png',
        prizes: [
            {
                title: 'Dizajnerski kalup za kuglof “Twist” Ø22',
                url: 'https://oetker-shop.hr/proizvod/dizajnerski-kalup-za-kuglof-twist-o22/'
            },
        ]
    },
    {
        id: 2,
        prize_image: 'HR-03.png',
        grid_image: 'KALENDAR-adventski_2-24.jpg',
        tail_image: 'rep-zvijezde-2-dan-min.png',
        prize_title: '',
        prize_url: '',
        prizes: [
            {
                title: 'Božićni bundle',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Kalup drvce, glazura, modlice čovječuljak i Christmas Glamour mix',
                url: ''
            }
        ]
    },
    {
        id: 3,
        prize_image: 'HR-05.png',
        grid_image: 'KALENDAR-adventski_3-24.jpg',
        tail_image: 'rep-zvijezde-3-dan-min.png',
        prizes: [
            {
                title: 'Poklon bon 50 eura',
                url: ''
            }
        ]
    },
    {
        id: 4,
        prize_image: 'HR-06.png',
        grid_image: 'KALENDAR-adventski_4-24.jpg',
        tail_image: 'rep-zvijezde-4-dan-min.png',
        prizes: [
            {
                title: 'Set za tortu',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Okrugli kalup izrazito visokog ruba 18 cm, Lopatica za zaglađivanje s visokim rubom, 24 cm i Pladanj za kolače rotirajući',
                url: ''
            }
        ]
    },
    {
        id: 5,
        prize_image: 'HR-09.png',
        grid_image: 'KALENDAR-adventski_5-24.jpg',
        tail_image: 'rep-zvijezde-5-dan-min.png',
        prizes: [
            {
                title: 'Taste of Christmas set',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: '1x Set za izradu i dekoraciju brownie kolača, 1x Set za božićne keksiće s dekorom, 1x Set za izradu kućice od medenjaka, + Christmas Party Dekor + Christmas Glue',
                url: ''
            }
        ]
    },
    {
        id: 6,
        prize_image: 'HR-08.png',
        grid_image: 'KALENDAR-adventski_6-24.jpg',
        tail_image: 'rep-zvijezde-6-dan-min.png',
        prizes: [
            {
                title: 'Set za Božićni kruh',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Kalup za pečenje božićnog kruha + Limun šećer+Instant kvasac 450g + Finesse naribana korica naranče +Kakao za kolače + Posuda za miješanje 3,2L',
                url: ''
            }
        ]
    },
    {
        id: 7,
        prize_image: 'HR-10.png',
        grid_image: 'KALENDAR-adventski_7-24.jpg',
        tail_image: 'rep-zvijezde-7-dan-min.png',
        prizes: [
            {
                title: 'Štapni mikser MSM6M622 BOSCH',
                url: ''
            }
        ]
    },
    {
        id: 8,
        prize_image: 'HR-09.png',
        grid_image: 'KALENDAR-adventski_8-24.jpg',
        tail_image: 'rep-zvijezde-8-dan-min.png',
        prizes: [
            {
                title: 'Taste of Christmas set',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: '1x Set za izradu i dekoraciju brownie kolača, 1x Set za božićne keksiće s dekorom, 1x Set za izradu kućice od medenjaka, + Christmas Party Dekor + Christmas Glue',
                url: ''
            }
        ]
    },
    {
        id: 9,
        prize_image: 'HR-02.png',
        grid_image: 'KALENDAR-adventski_9-24.jpg',
        tail_image: 'rep-zvijezde-9-dan-min.png',
        prizes: [
            {
                title: 'Mikser ručni MFQ49300 BOSCH',
                url: ''
            }
        ]
    },
    {
        id: 10,
        prize_image: 'HR-06.png',
        grid_image: 'KALENDAR-adventski_10-24.jpg',
        tail_image: 'rep-zvijezde-10-dan-min.png',
        prizes: [
            {
                title: 'Set za tortu',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Okrugli kalup izrazito visokog ruba 18 cm, Lopatica za zaglađivanje s visokim rubom, 24 cm i Pladanj za kolače rotirajući',
                url: ''
            }
        ]
    },
    {
        id: 11,
        prize_image: 'HR-07.png',
        grid_image: 'KALENDAR-adventski_11-24.jpg',
        tail_image: 'rep-zvijezde-11-dan-min.png',
        prizes: [
            {
                title: 'Retro set',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Retro okrugli kalup Ø20 cm, Roza retro kalup za pite & browniese, Roza četvrtasti kalup retro 25 cm',
                url: ''
            }
        ]
    },
    {
        id: 12,
        prize_image: 'HR-03.png',
        grid_image: 'KALENDAR-adventski_12-24.jpg',
        tail_image: 'rep-zvijezde-12-dan-min.png',
        prizes: [
            {
                title: 'Božićni bundle',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Kalup drvce, glazura, modlice čovječuljak i Christmas Glamour mix',
                url: ''
            }
        ]
    },
    {
        id: 13,
        prize_image: 'HR-05.png',
        grid_image: 'KALENDAR-adventski_13-24.jpg',
        tail_image: 'rep-zvijezde-13-dan-min.png',
        prizes: [
            {
                title: 'Poklon bon 50 eura',
                url: ''
            }
        ]
    },
    {
        id: 14,
        prize_image: 'HR-08.png',
        grid_image: 'KALENDAR-adventski_14-24.jpg',
        tail_image: 'rep-zvijezde-14-dan-min.png',
        prizes: [
            {
                title: 'Set za Božićni kruh',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Kalup za pečenje božićnog kruha + Limun šećer+Instant kvasac 450g + Finesse naribana korica naranče +Kakao za kolače + Posuda za miješanje 3,2L',
                url: ''
            }
        ]
    },
    {
        id: 15,
        prize_image: 'HR-07.png',
        grid_image: 'KALENDAR-adventski_15-24.jpg',
        tail_image: 'rep-zvijezde-15-dan-min.png',
        prizes: [
            {
                title: 'Retro set',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Retro okrugli kalup Ø20 cm, Roza retro kalup za pite & browniese, Roza četvrtasti kalup retro 25 cm',
                url: ''
            }
        ]
    },
    {
        id: 16,
        prize_image: 'HR-05.png',
        grid_image: 'KALENDAR-adventski_16-24.jpg',
        tail_image: 'rep-zvijezde-16-dan-min.png',
        prizes: [
            {
                title: 'Poklon bon 50 eura',
                url: ''
            }
        ]
    },
    {
        id: 17,
        prize_image: 'HR-09.png',
        grid_image: 'KALENDAR-adventski_17-24.jpg',
        tail_image: 'rep-zvijezde-17-dan-min.png',
        prizes: [
            {
                title: 'Taste of Christmas set',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: '1x Set za izradu i dekoraciju brownie kolača, 1x Set za božićne keksiće s dekorom, 1x Set za izradu kućice od medenjaka, + Christmas Party Dekor + Christmas Glue',
                url: ''
            }
        ]
    },
    {
        id: 18,
        prize_image: 'HR-02.png',
        grid_image: 'KALENDAR-adventski_18-24.jpg',
        tail_image: 'rep-zvijezde-18-dan-min.png',
        prizes: [
            {
                title: 'Mikser ručni MFQ49300 BOSCH',
                url: ''
            }
        ]
    },
    {
        id: 19,
        prize_image: 'HR-06.png',
        grid_image: 'KALENDAR-adventski_19-24.jpg',
        tail_image: 'rep-zvijezde-19-dan-min.png',
        prizes: [
            {
                title: 'Set za tortu',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Okrugli kalup izrazito visokog ruba 18 cm, Lopatica za zaglađivanje s visokim rubom, 24 cm i Pladanj za kolače rotirajući',
                url: ''
            }
        ]
    },
    {
        id: 20,
        prize_image: 'HR-08.png',
        grid_image: 'KALENDAR-adventski_20-24.jpg',
        tail_image: 'rep-zvijezde-20-dan-min.png',
        prizes: [
            {
                title: 'Set za Božićni kruh',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Kalup za pečenje božićnog kruha + Limun šećer+Instant kvasac 450g + Finesse naribana korica naranče +Kakao za kolače + Posuda za miješanje 3,2L',
                url: ''
            }
        ]
    },
    {
        id: 21,
        prize_image: 'HR-03.png',
        grid_image: 'KALENDAR-adventski_21-24.jpg',
        tail_image: 'rep-zvijezde-21-dan-min.png',
        prizes: [
            {
                title: 'Božićni bundle',
                url: 'https://oetker-shop.hr/okus-bozica'
            },
            {
                title: 'Kalup drvce, glazura, modlice čovječuljak i Christmas Glamour mix',
                url: ''
            }
        ]
    },
    {
        id: 22,
        prize_image: 'HR-04.png',
        grid_image: 'KALENDAR-adventski_22-24.jpg',
        tail_image: 'rep-zvijezde-22-dan-min.png',
        prizes: [
            {
                title: 'Dizajnerski kalup za kuglof “Floral” Ø24 + mješavina za kuglof',
                url: 'https://oetker-shop.hr/proizvod/dizajnerski-kalup-za-kuglof-floral-o24/'
            }
        ]
    },
    {
        id: 23,
        prize_image: 'HR-10.png',
        grid_image: 'KALENDAR-adventski_23-24.jpg',
        tail_image: 'rep-zvijezde-23-dan-min.png',
        prizes: [
            {
                title: 'Štapni mikser MSM6M622 BOSCH',
                url: ''
            }
        ]
    },
    {
        id: 24,
        prize_image: 'HR-01.png',
        grid_image: 'KALENDAR-adventski_24-24.jpg',
        tail_image: 'rep-zvijezde-24-dan-min.png',
        prizes: [
            {
                title: 'Kuhinjski aparat MUM9B34S27 BOSCH',
                url: 'https://oetker-shop.hr/proizvod/kuhinjski-aparat-mum9b34s27-bosch/'
            }
        ]
    },
]

export default advent_days