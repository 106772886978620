import './App.css';
import MainContent from './components/MainContent'
import Calendar from './components/Calendar'
import HeroContent from './components/HeroContent'
import bottomImage from './assets/img/OBRAZAC-BIH-donja-grafika.png'

function App() {
  return (
    <>
      <div className="main-content pb-20 pt-10">
        <div className="container mx-auto max-w-5xl bg-transparent">
          <HeroContent />
        </div>
        <div className="container mx-auto max-w-5xl px-0 lg:px-0 py-0 text-center bg-white">
          <MainContent />
        </div>
        <div className="container mx-auto max-w-5xl px-2 py-7 lg:px-8 lg:py-8 bg-white text-center">
          <Calendar />
         {/* <div className="flex justify-center">
            <img src={bottomImage} alt="graphic" style={{width: "400px"}} />
  </div>*/}
        </div>

      </div>
    </>
  );
}

export default App;
