import React from 'react'
import mainContentStar from '../assets/img/grafika/ZVIJEZDA-natpis-HR.png'
import mainContentPrizes from '../assets/img/HR-ZBIRNE-nagrade.png'
import DobitniciContent from '../components/DobitniciContent'

const MainContent = () => {

  return (
    <article className="mainContent pb-6">
      <div className="px-2 lg:px-9">
        <h1 className="font-bold text-2xl sm:text-3xl lg:text-4xl mb-8 lg:mb-10">Vrijeme darivanja je tu</h1>
        <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-8 lg:mb-10">Sudjelujte u velikoj nagradnoj igri ADVENTSKI KALENDAR!</h2>
        <p>Neka vam priprema slastica za najljepše doba godine prođe uz najaromatičnije proizvode iz Dr. Oetkera.</p>

        <p>Priprema kolača s najbližima mnogima je najdraža blagdanska aktivnost, a da bi ove blagdane upotpunili i unijeli još više radosti, mi smo za vas pripremili nagradni adventski kalendar u kojem možete osvojiti zanimljive i korisne pakete.</p>

        <p>Zavirite u adventski kalendar jer svaki dan u periodu trajanja nagradne igre od 01. do 24. prosinca 2024. godine imate priliku osvojiti vrijedne nagrade.</p>
        <br />
        <p><strong>Kako sudjelovati?</strong></p>
        <p>Slijedite par jednostavnih koraka jer nagrada može biti baš vaša! </p>
        <p>Otvorite vratašca kalendara za određeni datum, popunite polje obrasca sa svojim podacima te odgovorite na nagradno pitanje.</p>
        <br />
        <p>Sretno!</p>
        <DobitniciContent />
        {/*<a href="https://www.oetker.hr/hr-hr/svijet-dekora/dobitnici" target="_blank" className="basic-button">Dobitnici nagradnog natječaja</a>*/}
      </div>
      <div>
        <div className="flex justify-center">
          <img className="mainContentStar" src={mainContentStar} alt="advent information graphic" />
        </div>
        <div className="flex justify-center">
          <img className="mainContentPrizes" src={mainContentPrizes} alt="advent game prizes" />
        </div>
      </div>
    </article>
  )

}

export default MainContent